.subcategory{
	color: #fff;
	overflow: hidden;
	.s-row{
		position: absolute;
		display: flex;
		width: 100vw;
		height: 100vh;

		.s-col{
			flex: 1 1;
			
			&:first-child{
				max-width: 34.4vw;
				padding: 3.6vw 4.26vw 3.6vw 4vw;
				background-color: #5B6770;
			}

			h1{
				font-size: 5.3vw;
    			text-transform: uppercase;
				line-height: 5.9vw;
				white-space: nowrap;
				margin-bottom: 1.1rem;
			}

			div{
				&:nth-child(2){
					p{
						color: #E9DF97;
						font-size: 1.424vw;
						letter-spacing: 0.039vw;
						line-height: 1.84vw;
					}
				}
			}

			h2, p{
				font-family: 'Helvetica Neue LT Pro Medium Condensed', sans-serif;
			}

			h2{
				margin: 1.7vw 0 0;
				font-size: 1.5vw;
				line-height: 1.79vw;
				letter-spacing: 0.01vw;
			}

			p{
				font-size: 1.15vw;
				letter-spacing: 0.039vw;
				line-height: 1.72vw;

				ul{
					list-style: none;
					margin: 0.8vw 0;
					padding: 0;
					
					li{
						display: flex;
						&:before{
							content: '•';
							margin-right: 0.4vw;
						}
						margin-bottom: 0.8vw;
					}
				}
			}

			img{
				width: 100%;
				height: auto;
			}

			.video-js{
				width: 100%;
				height: 100%;
				video{
					opacity: 0;
					transition: opacity 0.75s;
				}
			}
		}
	}
}