.menu-item{

	.text-shadow{
		text-shadow: 0vw 0vw 0.9vw #00000094;
	}

	.normal-item{
		display: flex;
		justify-content: center;
		position: relative;
		height: 21.34vw;
		border: 0.47vw solid #F15A31;
		background-repeat: no-repeat;
		background-position: center;
		overflow: hidden;
		
		h1{
			position: absolute;
			top: 0.51vw;
			left: 0.51vw;
			text-transform: uppercase;
			letter-spacing: 0.006vw;
			font-size: 2.9vw;
			font-weight: 300;
		}

		// &>img{
		// 	min-height: 36.6vh;
		// 	object-fit: cover;
		// }

		.video-thumbnail{
			width: 100%;
			height: 100%;
			transition: opacity 0.75s;
			pointer-events: none;
			.video-js{
				width: 100%;
				height: 100%;

				video{
					object-fit: cover;
				}
			}
		}

		button{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: none;
			border: none;

			img{
				width: 15%;
			}
		}
	}

	.expanded-item{
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		overflow: hidden;
		z-index: 1;
		background-repeat: no-repeat;
		background-position: center;

		.v-background{
			position: absolute;
			width: 100%;
			height: 100%;
			
			z-index: -1;

			.video-js{
				width: 100%;
				height: 100%;
				video{
					position: absolute;
					top: 0;
					left: 0;
				}
			}
	
			// img{
			// 	object-fit: cover;
			// 	z-index: -2;
			// }
		}

		.content{
			height: 100%;
			padding: 2.8vw 3.7vw;
		}

		h1{
			// position: absolute;
			font-size: 9.8vw;
			text-transform: uppercase;
			letter-spacing: 0.18vw;
		}

		.btns{
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			gap: 1.2vw;
			margin: 11.6vw auto 0;

			button{
				width: 27.7vw;
				height: 5.3vw;
				border: 0;
				background-color: #F15A31;
				font-family: 'Helvetica LT Std Ultra Compressed', sans-serif;
				text-transform: uppercase;
				color: #fff;
				font-size: 3.24vw;
				text-align: left;
				padding: 0.7vw 1.4vw;
				box-shadow: 0.2vw 0.2vw 0.4vw #000000c7;
			}

			&.cols-2{
				width: 85vw;
			}
	  	}
	}
}