nav.pagination{
	position: absolute;
	bottom: 2.1vw;
	left: 3.9vw;

	ul{
		display: flex;
		justify-content: center;
		align-items: center;
		list-style: none;
		padding: 0;
		margin: 0;

		li{
			margin-right: 1rem;

			&:first-child{
				margin-right: 2.6vw;

				button{
					padding: 0.47vw 1.56vw;
				}
			}

			&:last-child{
				button{
					img{
						margin: 0.25vw 0 0 0.25vw;
					}
				}
			}

			button{
				border: none;
				background-color: #F15A31;
				padding: 0.1vw 1.14vw;
				color: #fff;
				font-family: 'Helvetica Neue LT Pro Medium Condensed', sans-serif;
				font-size: 1.2vw;
				border-radius: 1vw;

				span{
					display: block;
					height: 1vw;
					line-height: 1.45vw;
				}

				img{
					width: 1.2vw;
					height: auto;
					margin: 0.25vw 0.25vw 0 0;
				}
			}
		}
	}
}