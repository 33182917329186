$color-theme-red: #f15a31;
$color-theme-link-red: #f9544d;
$color-theme-yellow: #e9df97;
$color-theme-gray: #adb3b8;
$color-theme-gray2: #646F78;
$color-theme-dark-gray: #1a1a1a;

$font-theme-default: 'Source Sans Pro', sans-serif;
$font-theme-heading: 'Barlow Condensed', sans-serif;
$font-theme-quote: 'IM Fell Double Pica', serif;
$font-theme-scala-sans: 'ScalaSansWebPro', serif;

@font-face {
    font-family: 'ScalaSansWebPro';
    src: url('/fonts/ScalaSansWebPro.eot') format('eot'),
    url('/fonts/ScalaSansWebPro.woff') format('woff');
    font-weight: 100 700;
    font-style: normal;
}

@font-face {
    font-family: 'ScalaSansWebPro-Bold';
    src: url('/fonts/ScalaSansWebPro-Bold.eot') format('eot'),
    url('/fonts/ScalaSansWebPro-Bold.woff') format('woff');
    font-weight: 100 700;
    font-style: normal;
}

@font-face {
    font-family: 'ScalaSansOT-Light';
    src: url('/fonts/ScalaSansOT-Light.otf') format('opentype');
    font-weight: 100 700;
    font-style: normal;
}

@font-face {
    font-family: 'ScalaSansOT-Light-Ita';
    src: url('/fonts/ScalaSansOT-LightIta.otf') format('opentype');
    font-weight: 100 700;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica LT Pro Ultra Compressed';
    src: url('/fonts/Linotype_-_HelveticaLTPro-UltComp.otf') format('opentype');
    font-weight: 100 700;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica LT Std Ultra Compressed';
    src: url('/fonts/Linotype_-_HelveticaLTStd-UltraComp.otf') format('opentype');
    font-weight: 100 700;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro Condensed';
    src: url('/fonts/Linotype_-_HelveticaNeueLTPro-Cn.otf') format('opentype');
    font-weight: 100 700;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro Medium Condensed';
    src: url('/fonts/Linotype_-_HelveticaNeueLTPro-MdCn.otf') format('opentype');
    font-weight: 100 700;
    font-style: normal;
}

body{
    background-color: #000;
    overflow: hidden;
    user-select: none;
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

h1, h2, h3, h4 {
    font-family: Helvetica LT Std Ultra Compressed, sans-serif;
}

p {
    font-family: Helvetica Neue LT Pro Medium Condensed, sans-serif;
}

.bg-dark-gray {
    background-color: $color-theme-dark-gray;
}

.bg-black {
    background-color: #000;
}

.text-red {
    color: $color-theme-red;
}

.text-yellow {
    color: $color-theme-yellow;
}

.text-gray {
    color: $color-theme-gray;
}

.navbar {

    &.navbar-theme-red {

        background-color: $color-theme-red;

        .nav-item {

            &.active {

                a.nav-link {
                    color: #FFF;
                }
            }
        }

        a.nav-link {
            color: $color-theme-yellow;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
}

.project-nav-wrapper {

    display: flex;

    .project-nav-item {
        width: 400px;
        margin-right: 8px;
        padding: 14px;
        background-color: #efefef;
    }
}

.menu-grid{
    display: grid;
    gap: 3.5vw;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    padding: 3.9vw 3.9vw 1.2vw;
}

footer{
    color: #F15A31;
    text-align: center;
    font-size: 2.03vw;
    font-weight: bolder;
    font-family: 'ScalaSansWebPro-Bold';
}